@use 'variables/color';

.kyc {
  .table {
    > li {
      &:not(&:first-of-type) {
        border-bottom: 1px solid #eee;
        & > * {
          padding: 20px 10px 20px;
          display: flex;
          align-items: center;
          &:first-of-type {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

.table {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  &__head {
    display: grid;
    background-color: color.$athensGray;
    border-radius: 4px;

    /* kycSortable header directive */
    &__item {
      user-select: none;
      align-items: center;
      -webkit-user-select: none;
      text-transform: uppercase;
      display: inline-flex;
      font-weight: 700;
      transition: all 0.4s;
      padding: 10px;

      &:first-of-type {
        padding-left: 20px;
      }

      &.sort {
        cursor: pointer;
        &:hover {
          background-color: color.$gallery;
        }
      }

      &.desc:after,
      &.asc:after {
        content: '';
        display: block;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
          no-repeat;
        background-size: 16px;
        width: 16px;
        height: 16px;
        float: right;
        margin-left: 8px;
        margin-top: 2px;
      }

      &.desc:after {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
      }
    }
  }
}
