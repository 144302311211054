@import 'variables';

table {
  width: 100%;
}

.table-total {
  font-weight: $fw-bold;
}

.table-list-container {
  overflow-x: auto;
  margin: 0 -10px;
  padding: 0 10px 5px;
}

.table-list {
  min-width: fit-content;
  > li {
    display: grid;
    grid-column-gap: 16px;
    padding: 10px 0;
    min-height: 60px;
    align-items: center;
    transition: all 0.2s;
    &.table-title {
      color: #7e84a3;
      > *:last-child {
        padding-right: 10px;
      }
    }
    &:not(.table-title):not(:first-child) {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      &:not(.not-hover):not(.disabled):hover {
        background-color: #f5f6fa;
        cursor: pointer;
        box-shadow: 0 1px 4px rgb(21 34 50 / 15%);
        z-index: 10;
        position: relative;
      }
    }
    &.disabled {
      cursor: not-allowed !important;
    }
    > *:first-child {
      padding-left: 10px;
    }
    > *:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    > * {
      //@include max-lines(2);
    }
  }
}

.table-stats {
  min-width: fit-content;
  > li {
    display: grid;
    padding: 10px 0;
    align-items: center;
    > * {
      padding: 0 10px;
    }
    > *:not(:first-child) {
      text-align: center;
    }
    &:first-child {
      color: #7e84a3;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      align-items: flex-end;
    }
    &:not(:first-child) {
      border-radius: 5px;
    }
    &:nth-child(even) {
      background: #f9f9f9;
    }
  }
}

.table-l-h_1 {
  line-height: 1;
  padding-top: 2px;
}
