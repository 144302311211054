.form-control {
  margin-bottom: 10px;
}

%select-arrow {
  height: 6px !important;
  width: 6px !important;
  transform: rotate(45deg);
  border-color: $geyser;
  transform-origin: center center;
  border: 0;
  border-bottom: 2px solid;
  border-right: 2px solid;
  margin: 0 2px 0 0;
}

%error-state {
  position: relative;
  padding-left: 20px;
  padding-top: 1px;
  font-size: $fs-lt !important;

  &:before {
    font-family: 'Material Icons';
    content: 'warning';
    height: 12px;
    width: 12px;
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 15px;
  }
}

.kyc {
  kyc-input,
  kyc-select,
  kyc-textarea,
  ng-select,
  kyc-date-picker,
  kyc-text-editor {
    .mat-form-field {
      width: 100%;

      &.has-hint {
        .mat-form-field-wrapper {
          padding-bottom: 1.4375em !important;
        }
      }

      &.has-prefix {
        .mat-form-field-label-wrapper {
          left: -44px;
        }
      }
      &:not(.with-field-state) {
        .mat-form-field-prefix,
        .mat-form-field-suffix {
          color: $accent-400;
          top: 0;
        }
      }

      &-prefix {
        padding-right: 8px;
        margin: auto;

        & > span {
          display: flex;
          align-items: center;
        }
      }

      .mat-input-element {
        color: $black;
      }

      &-disabled {
        .mat-input-element,
        .mat-select-value-text {
          color: rgba($black, 0.5);
        }

        .mat-form-field {
          &-outline > div {
            background-color: $porcelain;
          }
        }
      }

      &:not(.without-label) .mat-form-field-wrapper {
        margin-top: 25px;
      }

      &:not(.mat-form-field-invalid) .mat-form-field-wrapper {
        padding-bottom: 8px;
      }

      &-flex {
        margin-top: 0;
        background-color: $white;
        border-radius: 5px;
      }

      &-infix,
      .ng-value-container {
        border-top: 0 !important;
        padding: 10px 0;
      }

      &-infix {
        @include media('<maxPhone') {
          width: 100%;
        }
      }

      &-outline {
        top: 0;

        &-gap {
          display: none;
        }

        &:not(.mat-form-field-outline-thick) > div {
          border-color: $geyser;
        }

        &.mat-form-field-outline-thick > div {
          border-color: $primary-400;
        }

        & > div {
          border-width: 1px !important;
        }
      }

      &-invalid {
        .mat-form-field-outline.mat-form-field-outline-thick > div {
          border-color: $warn-400;
        }
      }

      &-label {
        font-weight: $fw-semi-bold;
        font-size: $fs-big;
        margin-top: -6px;
        color: $black !important;

        &-wrapper {
          left: -12px;
          top: -28px;
          padding-top: 28px;
        }
      }

      .mat-select-arrow {
        @extend %select-arrow;

        &-wrapper {
          transform: translateY(-15%);
        }
      }

      .mat-form-field-subscript-wrapper {
        padding-left: 0;
      }
    }

    .mat-error {
      @extend %error-state;
    }

    .mat-hint {
      color: $silver-chalice;
      font-size: $fs-sm;
    }
  }

  .ng-select {
    & + .mat-error {
      @extend %error-state;
    }

    .ng-select-container {
      border-radius: 6px;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, 0.12);

      &:after {
        background-color: $white;
        min-height: 40px;
        z-index: 0;
      }
    }

    .ng-clear-wrapper,
    .ng-arrow-wrapper {
      z-index: 1;
    }

    &.admin__search .ng-select-container.ng-appearance-outline {
      border-radius: 6px;
      background-color: white;
      min-height: 42px;
    }

    &.ng-select-multiple {
      .ng-value-container {
        padding: 10px 0 5px !important;

        .ng-value {
          z-index: 1;
          padding: 2px 5px !important;
          display: flex;
          flex-direction: row-reverse;

          .ng-value-icon {
            margin-left: 3px;
            margin-top: 2px;
            font-size: 22px;
          }
        }

        .ng-input {
          padding-left: 0;
        }
      }
    }

    &-invalid {
      padding-bottom: 6px;

      .ng-select-container.ng-appearance-outline:after {
        border-color: $warn-400 !important;
      }

      &,
      &.ng-select-focused {
        .ng-arrow {
          color: $warn !important;
        }
      }

      .ng-input input {
        caret-color: $warn;
      }
    }

    &:not(.ng-select-invalid) {
      .ng-select-container.ng-appearance-outline:hover:after {
        border-color: $primary-400 !important;
      }

      &.ng-select-focused {
        .ng-select-container.ng-appearance-outline:after {
          border-color: $primary-400 !important;
        }

        .ng-arrow {
          color: $primary !important;
        }

        .ng-input input {
          caret-color: $primary;
        }
      }
    }

    .ng-input {
      padding: 0 40px 2px 12px;

      input {
        font-size: $fs;
      }
    }

    .ng-dropdown-panel {
      font-size: $fs;

      .ng-dropdown-panel-items .ng-option {
        padding: 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      &.ng-select-bottom {
        top: unset;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 4px 6px;
        background-color: #fff;
        border-radius: 4px;
      }
    }

    &-container.ng-appearance-outline {
      min-height: 40px;
      padding: 0 12px;

      &:after {
        min-height: 40px;
        border-width: 1px !important;
      }

      .ng-value-container {
        border: none;
        padding: 0;
        align-items: center;

        .ng-placeholder {
          position: relative;
          background: none;
          transform: none;
          color: rgba($black, 0.45) !important;
        }

        .ng-placeholder,
        .ng-value {
          font-size: $fs;
          padding: 10px 0 7px;
          line-height: 20px;
        }
      }
    }

    &-filtered,
    .ng-has-value {
      .ng-placeholder {
        display: none !important;
      }
    }

    .ng-clear-wrapper {
      bottom: -3px !important;
    }

    .ng-arrow {
      @extend %select-arrow;

      &-wrapper {
        bottom: 2px !important;
      }
    }
  }

  kyc-date-picker {
    .mat-form-field {
      &-invalid {
        .mat-datepicker-toggle .mat-icon-button {
          color: $warn;
        }
      }

      &-label-wrapper {
        left: -41px;
      }

      &-prefix {
        padding-right: 5px;

        .mat-icon {
          color: rgba($black, 0.45) !important;
          line-height: 1;
          transform: translateY(5%);
        }
      }

      &-suffix .mat-datepicker-toggle .mat-icon-button {
        height: 25px;
        width: 25px;
        transform: translate(6px, 16%);

        .mat-button-ripple,
        .mat-button-focus-overlay {
          display: none;
        }
      }
    }
  }

  .mat-menu-panel {
    .mat-checkbox {
      padding: 0;
    }
  }

  .mat-checkbox {
    padding: 6px 0;

    &-layout {
      white-space: normal;
    }

    &-disabled {
      opacity: 0.3;
    }

    &-checked.mat-primary .mat-checkbox-background {
      background-color: $primary-600;
    }

    &-inner-container {
      height: 18px;
      width: 18px;
      margin-right: 0;
    }

    &-label {
      margin-left: 15px;
    }

    &-frame {
      background: $white;
      border-width: 1px;
      border-color: $accent-200;
      border-radius: 3px;
    }

    &.user-ns-list__checkbox .mat-checkbox-label {
      margin-left: 4px;
    }
  }

  kyc-checkbox.expired__domains-cb .mat-checkbox-label {
    margin-left: 4px;
  }

  .mat-radio-button .mat-radio-label {
    white-space: normal;
  }

  &-checked.mat-primary .mat-checkbox-background {
    background-color: $primary-600;
  }

  quill-editor {
    .ql-toolbar,
    .ql-container {
      border-color: $geyser;
    }

    .ql-tooltip {
      left: 10px !important;
    }

    .ql-toolbar {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-color: $geyser !important;
    }

    .ql-container {
      height: calc(100% - 45px);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:not(.ng-invalid) {
      &:hover,
      &:focus,
      &:active {
        .ql-toolbar,
        .ql-container {
          border-color: $primary-400;
        }
      }
    }

    &.ng-touched.ng-invalid {
      .ql-container,
      .ql-toolbar {
        border-color: $warn-400;
      }
    }
  }

  .mat-option {
    .price_option {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      line-height: 1.2;

      &__name {
        font-weight: 700;
      }
    }
  }

  .applicants__filters {
    kyc-date-picker {
      .mat-form-field:not(.without-label) .mat-form-field-wrapper {
        margin: 0.25em 0;

        .mat-form-field-suffix .mat-datepicker-toggle .mat-icon-button {
          height: 16px;
          width: 20px;

          .mat-icon {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .mat-form-field-prefix .mat-icon {
          font-size: 20px;
        }
      }
    }
  }

  .tag-list {
    &.mat-form-field {
      width: 100%;
      .mat-form-field-infix {
        padding-top: 0;
        padding-bottom: 10px;
      }
      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding-top: 0;
        padding-bottom: 10px;
      }
    }
  }
}
