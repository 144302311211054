@import '../variables';

.user_status {
  &_approved {
    color: $status_done;
  }

  &_waitingApprove {
    color: $status_pending;
  }
  &_locked {
    color: $status_declined;
  }
}

.status-active {
  color: $status_done;
}
.status-pending {
  color: $status_pending;
}

.status-not-active {
  color: $status_declined;
}

.c-red {
  color: $status_declined;
}

.status {
  border: 1px solid;
  padding: 2px 8px;
  border-radius: 50px;
  text-align: center;
}
