.kyc {
  .analyzer__photos__item__photo {
    .ngxImageZoomContainer {
      width: unset !important;
      height: unset !important;
      .ngxImageZoomThumbnail {
        width: 100%;
      }
      .ngxImageZoomFullContainer.ngxImageZoomLensEnabled {
        border: 4px solid #fff;
        border-radius: 4px !important;
      }
    }
  }
}
