.kyc {
  .mat-button-toggle-group {
    border-color: $white;
  }
  .mat-button-toggle {
    background: transparent;
    font-weight: $fw-bold;
    color: $white;
    cursor: pointer;
    & + .mat-button-toggle {
      border-color: $white;
    }
    &-checked {
      background: $white;
      color: $primary;
    }
    .mat-button-toggle-label-content {
      line-height: 36px;
    }
  }

  .mat-slide-toggle {
    &-thumb {
      width: 18px;
      height: 18px;
      transform: translateX(15%);
    }

    &-bar {
      border-radius: 15px;
      height: 22px;
      background-color: #ced4da;
    }

    &-thumb-container {
      top: 2px;
    }

    &.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
      background-color: $white;
      transform: translateX(-5%);
    }
    &[large] {
      height: 34px;
      line-height: 34px;
      .mat-slide-toggle-thumb {
        width: 26px;
        height: 26px;
        transform: translateX(15%);
      }

      .mat-slide-toggle-bar {
        height: 32px;
        width: 60px;
      }
      .mat-slide-toggle-thumb-container {
        top: 3px;
      }
      .mat-ripple {
        top: calc(50% - 17px);
        left: calc(50% - 14px);
      }
      &.mat-checked:not(.mat-disabled) {
        .mat-slide-toggle-thumb {
          transform: translateX(52%);
        }
        .mat-ripple {
          left: calc(100% - 12px);
        }
      }
    }
  }
}
