.kyc {
  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled='false'] {
    background-color: unset;
  }

  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }
}
