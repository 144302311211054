.kyc {
  .mat-menu-item {
    .mat-icon-no-color {
      color: unset;
    }
  }

  .mat-menu-panel.notification__menu {
    min-width: 400px;
  }
}
