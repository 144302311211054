@mixin kyc-theme-reset($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  html,
  body {
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }

  a {
    color: mat-color($primary, text);
    font-size: 0.875rem;
    text-decoration: none;
  }

  button,
  button[type='button'],
  button[type='submit'],
  [type='button'],
  [type='submit'] {
    appearance: none;
  }

  // Hide arrows
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
