@import '../variables/spacing';

$offset-scale: (
  0: $spacing-0,
  1: $spacing-1,
  2: $spacing-2,
  3: $spacing-3,
  4: $spacing-4,
  5: $spacing-5,
  6: $spacing-6,
  7: $spacing-7,
  8: $spacing-8,
  9: $spacing-9,
  10: $spacing-10,
);
$offset-direction: (top, bottom, right, left);
$offset-kind: (padding, margin);

.kyc {
  @each $kind in $offset-kind {
    @each $level, $offset in $offset-scale {
      .#{$kind}-#{$level} {
        #{$kind}: $offset;
      }

      @each $dir in $offset-direction {
        .#{$kind}-#{$dir}-#{$level} {
          #{$kind}-#{$dir}: $offset;
        }
      }

      .#{$kind}-horizontal-#{$level} {
        #{$kind}-left: $offset;
        #{$kind}-right: $offset;
      }

      .#{$kind}-vertical-#{$level} {
        #{$kind}-top: $offset;
        #{$kind}-bottom: $offset;
      }
    }
  }
}
