@import 'variables';

.no-pointer-events {
  pointer-events: none;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.overflow-wrap-break-word {
  overflow-wrap: anywhere;
}

.white-space-normal {
  white-space: normal;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.white-space-pre-line {
  white-space: pre-line;
}

.uppercase {
  text-transform: uppercase;
}

.truncate,
.truncate-child > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-child {
  overflow: hidden;
}

.line-height-normal {
  line-height: normal;
}

.w100 {
  width: 100%;
}

.w75 {
  width: 75%;
}

.w25 {
  width: 25%;
}

.w50 {
  width: 50%;
}

.w33 {
  width: 33%;
}

.h100 {
  height: 100%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.separator {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.pt-50 {
  padding-top: 50px;
}

.pt-20 {
  padding-top: 20px;
}

.fs-20 {
  font-size: 20px;
}

.text-bold {
  font-weight: 700 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.d-block {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

@mixin max-lines($number, $line-height) {
  height: #{$number * $line-height}em;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-wrapper {
  background: $white;
  box-shadow: 0 4px 10px rgba($black, 0.15);
  overflow: hidden;
  border-radius: 10px;
  padding: 30px;
  margin: 0 10px 10px 0;
}

.m-l_a {
  margin-left: auto;
}

.m-b_20 {
  margin-bottom: 20px;
}

.d_f {
  display: flex;
  flex-wrap: wrap;
}

.a-i_c {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.j-c_f-e {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.j-c_s-b {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.c-g_10 {
  column-gap: 10px;
}

.c-g_20 {
  column-gap: 20px;
}

.labeled-field {
  display: flex;
  align-items: baseline;
  &__label {
    width: 40%;
    font-size: $fs;
  }

  &__field {
    width: 60%;
  }
}

.ver-labeled-field {
  display: flex;
  flex-direction: column;

  &__label {
    width: 100%;
    font-weight: 700;
    font-size: $fs;
  }

  &__field {
    width: 100%;
  }
}

.science-blue {
  color: $scienceBlue;
}

.alizarin-crimson {
  color: $alizarinCrimson;
}

.back__link {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  column-gap: 10px;
  font-size: 16px;
  color: $royalBlue;

  mat-icon {
    color: $black;
  }
}
