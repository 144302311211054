/* You can add global styles to this file, and also import other style files */
@import 'scss/index';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Montserrat, sans-serif;
}
