@import 'variables';

.kyc {
  .compact__tabs {
    .mat-tab-header {
      border-bottom: none;
    }

    .mat-tab-label {
      font-size: 16px;
      min-width: unset;
      opacity: 1;
      color: $black;
      @include media('<maxPhone') {
        font-size: 18px;
        padding: 0 10px;
      }
      &.mat-tab-label-active {
        font-weight: 700;
        color: $primary;
      }
    }

    .mat-ink-bar {
    }
  }

  .support__ticket_new .mat-tab-header {
    display: none;
  }

  .mat-tab-group.headless {
    .mat-tab-header {
      display: none;
    }
  }

  .hide-tab-label .mat-tab-label-container {
    display: none !important;
  }
}
