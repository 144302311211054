@use '@angular/material' as mat;
@import '../themes/_pallete';

$primary-400: mat.get-color-from-palette($kyc-primary-pallete, 400);
$primary: mat.get-color-from-palette($kyc-primary-pallete, 500);
$primary-600: mat.get-color-from-palette($kyc-primary-pallete, 600);
$primary-700: mat.get-color-from-palette($kyc-primary-pallete, 700);
$primary-800: mat.get-color-from-palette($kyc-primary-pallete, 800);
$primary-900: mat.get-color-from-palette($kyc-primary-pallete, 900);
$warn-400: mat.get-color-from-palette($kyc-danger-pallete, 400);
$warn: mat.get-color-from-palette($kyc-danger-pallete, 500);
$accent-200: mat.get-color-from-palette($kyc-secondary-pallete, 200);
$accent-300: mat.get-color-from-palette($kyc-secondary-pallete, 300);
$accent-400: mat.get-color-from-palette($kyc-secondary-pallete, 400);
$accent: mat.get-color-from-palette($kyc-secondary-pallete, 500);

/***************************************************/

$black: #000;
$ebonyClay: #293241;
$mirage: #1c2535;
$royalBlue: #5138ee;
$cornflower-blue: #6366f1;
$scienceBlue: #0174df;
$portage: #9788f5;
$dodgerBlue: #3b82f6;
$cadetBlue: #b1bbc7;

$royalBlue: #5138ee;
$mountain-meadow: #22c55e;
$turquoiseBlue: #5ce7c4;
$gray: #808080;
$dustyGray: #999999;
$azureRadiance: #0399ff;
$flamenco: #ff810d;
$sazerac: #fff2e2;
$alizarinCrimson: #eb263e;

$cerulean: #00a8e7;
$geyser: #d8dde3;
$grey: #9ca2ab;
$silver: #bebebe;
$silver-chalice: #9e9e9e;
$tropicalBlue: #c2dcf8;
$linkWater: #c6dbf2;
$solitude: #e2f0fe;
$gallery: #eeeeee;
$athensGray: #f5f7f9;
$alabaster: #f8f8f8;
$white: #fff;
$mystic: #d7dfe8;
$geyser: #e0e4e8;
$porcelain: #f0f2f3;

$status_inProgress: rgba(0, 0, 0, 0.6);
$status_done: $mountain-meadow;
$status_declined: $alizarinCrimson;
$status_pending: $flamenco;
