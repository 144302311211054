$fs-lg: 26px;
$fs-big: 20px;
$fs: 16px;
$fs-sm: 14px;
$fs-lt: 12px;

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
