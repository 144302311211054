.kyc {
  .mat-button {
    .mat-button-wrapper {
      display: flex;
      column-gap: 6px;
      align-items: center;
      justify-content: center;
    }
  }
}
