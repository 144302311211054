@use 'base/media';
@use 'variables/sizes';

.kyc {
  .auth {
    width: 40%;
    padding: 50px 10px 50px 100px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    font-size: 16px;
    @include media.max-media-query(sizes.$ww-1279) {
      padding: 20px;
    }
    @include media.max-media-query(sizes.$ww-1023) {
      width: 100%;
    }
    a {
      font-size: 16px;
      align-self: flex-start;
    }

    &__wrapper {
      width: 90%;
      box-sizing: border-box;
      padding: 20px;

      background: linear-gradient(180deg, #e1f3ff 0%, #ebfbfa 100%);
      border-radius: 50px;

      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media.max-media-query(sizes.$ww-767) {
        width: 96%;
        padding: 10px;
      }
    }

    &__image {
      width: 54%;
      height: 100%;
      @include media.max-media-query(sizes.$ww-767) {
        display: none;
      }
    }
    &__action {
      margin-top: 30px;
    }
  }
}
