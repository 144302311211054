@mixin min-media-query($size) {
  @media (min-width: #{$size}px) {
    @content;
  }
}

@mixin max-media-query($size) {
  @media (max-width: #{$size}px) {
    @content;
  }
}
