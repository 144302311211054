.kyc {
  .image-manager {
    &__cropper {
      .ngx-ic-cropper {
        color: transparent !important;
        outline: unset !important;
      }
      .ngx-ic-overlay {
        outline: unset !important;
      }
    }
  }
}
