@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat-Regular'),
    url('/assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('/assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Thin'), local('Montserrat-Thin'),
    url('/assets/fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url('/assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('/assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
    url('/assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
