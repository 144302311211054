@use 'variables/color';

.kyc {
  .mat-card {
    &.mat-card-wrapper {
      background: color.$white;
      box-shadow: 0 4px 10px rgba(color.$black, 0.15);
      overflow: hidden;
      border-radius: 10px;
      padding: 30px;
      margin: 10px 10px 10px 0;

      .mat-card-title {
        font-size: 26px;
        margin-bottom: 20px;
      }

      .mat-card-subtitle {
        font-size: 16px;
        color: color.$black;
      }

      .mat-card-actions {
        display: flex;
        align-items: center;
        column-gap: 15px;
        margin-left: 0;
        padding-right: 0;
      }
    }
  }
}
